import React from "react";

// import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faChevronRight);

function IndexPage() {
  return (
    <Layout headerTransparent={false}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section
        id="offer"
        className="section bg-white py-16 pt-8"
        style={{
          background: "linear-gradient(rgba(255,255,255,1), rgba(0,0,0,0.1))",
        }}
      >
        <div className="max-w-3xl mx-auto text-center px-3">
          <h2 className="text-5xl mb-6 font-normal">
            Grab Our <span className="underline font-bold">Ultimate</span>{" "}
            <span className="whitespace-no-wrap">Life-Coaching</span> Website
            Package Offer
            <br />
            Right Now.
          </h2>
          <h3 className="font-light mb-10 leading-tight text-3xl">
            <FontAwesomeIcon
              icon={["fas", "quote-left"]}
              className="text-red-700"
            />{" "}
            Everything You Need To <em>Sky-Rocket</em> Your Coaching Business,
            In One High-Performance Package.{" "}
            <FontAwesomeIcon
              icon={["fas", "quote-right"]}
              className="text-red-700"
            />
          </h3>
        </div>
        <div className="max-w-6xl mx-auto">
          <div className="lg:flex">
            <div
              className="lg:w-1/2 bg-black shadow-xl py-64"
              style={{
                backgroundImage: `url(${require("../images/success-man.jpg")})`,
                backgroundAttachment: "static",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="lg:w-1/2 p-10 pt-6 text-center lg:text-left">
              <h4 className="font-bold leading-tight">
                Get More Clients <span>Coming To You</span>, Booking Sessions,
                and Sending You Online Payments—Effortlessly.
              </h4>
              <h6 className="text-gray-800 font-light">
                Offer available to the first 10 customers only*
              </h6>
              <div className="md:flex flex-col justify-center">
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>Bespoke Web Design &amp; Development</span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>Mobile, Tablet &amp; Desktop Responsive Design</span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>Search Engine Optimization (SEO)</span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>Website Performance Tuning</span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>UX Design</span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>
                    Online Booking &amp; Calendar Integration (Calendly)
                  </span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>Full Online Payment Processing (Calendly)</span>
                </div>
                {/* <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>Digital Marketing AD Campaign Management</span>
                </div> */}
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-green-700"
                  />
                  <span>Unrivaled Support</span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-orange-600"
                  />
                  <span>BONUS: Full Domain Name &amp; E-mail Setup</span>
                </div>
                <div className="text-xl p-1">
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    className="mr-2 text-orange-600"
                  />
                  <span>BONUS: 3 Months FREE Website Maintenance</span>
                </div>
              </div>
              <div className="mb-6">
                <span className="text-3xl">
                  <del>
                    <strong className="font-bold">$19,995</strong>
                  </del>
                </span>{" "}
                <span className="text-3xl">
                  <strong className="font-bold text-red-700">
                    $9,997
                    <br />
                    (50% OFF! Limited time offer)
                  </strong>
                </span>
              </div>
              <div>
                <div className="inline-flex items-center bg-green-800 text-white px-8 py-5 font-bold uppercase shadow-xl cursor-pointer">
                  <span>Claim Our Ultimate Website Offer</span>
                  <FontAwesomeIcon
                    icon={["fas", "chevron-right"]}
                    className="ml-3 text-white opacity-50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-center max-w-xl mx-auto font-light">
            What our <strong className="font-bold">wonderful</strong> clients
            are saying...
          </h2>
          <div className="lg:flex text-center text-lg">
            <div className="lg:w-1/3 p-5">
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={["fas", "quote-left"]}
                  className="text-gray-700"
                />{" "}
                I am very pleased with the re-design of my website by
                McFarlaneTech. For a while I did not feel connected with my
                previous website—it was slow and looked outdated. My
                instructions for a sophisticated, fast-loading, dynamic website
                have been followed to a tee and I could not be happier, thank
                you so much.{" "}
                <FontAwesomeIcon
                  icon={["fas", "quote-right"]}
                  className="text-gray-700"
                />
              </p>
              <div className="text-3xl text-center mt-3">
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
              </div>
            </div>

            <div className="lg:w-1/3 p-5">
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={["fas", "quote-left"]}
                  className="text-gray-700"
                />{" "}
                McFarlaneTech&apos;s dedication and passion for what they do are
                exemplified by their unrivaled customer support and
                attentiveness to the specific needs of my business. They met all
                of my expectations and had a lot of insight regarding marketing
                tools I didn&apos;t know existed. To me, creating and building a
                website can be so stressful, but with McFarlaneTech, it never
                was. It was fun and exciting.{" "}
                <FontAwesomeIcon
                  icon={["fas", "quote-right"]}
                  className="text-gray-700"
                />
              </p>
              <div className="text-3xl text-center mt-3">
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
              </div>
            </div>
            <div className="lg:w-1/3 p-5">
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={["fas", "quote-left"]}
                  className="text-gray-700"
                />{" "}
                McFarlaneTech were so patient and kind. We went over the design
                on the phone and in-person and they would send immediate
                updates. My website turned out, just as I dreamed. Mere words
                cannot describe my 100% appreciation for McFarlaneTech in
                developing a professional and user-friendly website.{" "}
                <FontAwesomeIcon
                  icon={["fas", "quote-right"]}
                  className="text-gray-700"
                />
              </p>
              <div className="text-3xl text-center mt-3">
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
                <FontAwesomeIcon
                  icon={["fas", "star"]}
                  className="text-yellow-500"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="text-center">
        <div className="inline-flex items-center bg-green-800 text-white px-8 py-5 font-bold uppercase shadow-xl cursor-pointer">
          <span>Claim Our Ultimate Website Offer</span>
          <FontAwesomeIcon
            icon={["fas", "chevron-right"]}
            className="ml-3 text-white opacity-50"
          />
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
